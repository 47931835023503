import $ from 'jquery-slim';

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})






